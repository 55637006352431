import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Rails        from '@rails/ujs'
import Turbolinks   from 'turbolinks'
import * as Trix    from 'trix'

import 'what-input'
import 'controllers'

// Trix
Trix.config.lang.urlPlaceholder = 'Enter a URL prefixed with http:// or https://'

// lib
require('lib')

// images
require.context('../images', true)

function setup() {
  return undefined
}

function teardown() {
  return undefined
}

const Application = {
  setup,
  teardown
}

document.addEventListener('turbolinks:load', Application.setup, { once: true })
document.addEventListener('turbolinks:render', Application.setup)
document.addEventListener('turbolinks:before-render', Application.teardown)

// Rails & Turbolinks
Turbolinks.start()
Rails.start()

// ActiveStorage
// require('@rails/activestorage').start()

require('./nested-forms/addFields');
require('./nested-forms/removeFields');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)



require('@rails/actiontext')

// Disable file uploads for WYSIWYG
document.addEventListener('trix-file-accept', function(event) {
  event.preventDefault();
});
