import ApplicationController from 'support/application_controller'
import $fq                   from 'support/fake_query'
import Rails                 from '@rails/ujs'

export default class extends ApplicationController {

  static targets = [
    'discountCodeInput',
    'discountValue',
    'total'
  ]

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  select(event) {
    const element = event.currentTarget

    this.setDiscountValue('valid', '')
    this.discountCodeInputTarget.value = ''
    this.updateTotal(element.value)
  }

  checkDiscountCode(event) {
    event.preventDefault()

    Rails.ajax({
      url:        event.currentTarget.href,
      type:       'GET',
      data:       this.params({ discount_code: this.discountCode }),
      dataType:   'script',
      beforeSend: (xhr, options) => {
        return (xhr, options)
      },
      success:    (_response, _status, xhr) => {
        const json  = JSON.parse(xhr.response)
        const price = this.getInformationFromSelection(this.selectedProductId)

        if (json.value == 'invalid') {
          this.setDiscountValue('invalid', 'Invalid code')
          this.updateTotalHTML(price)
        } else {
          var promotionCodeDiscountAmount = json.value

          var discount = 0
          if(promotionCodeDiscountAmount % 1 == 0) { // fixed amount discount
            discount = promotionCodeDiscountAmount
          } else { // percentage discount
            discount = promotionCodeDiscountAmount * price
          }

          this.setDiscountValue('valid', `- ${this.formatCurrency(discount)}`)
          this.updateTotalHTML(price - discount)
        }
      },
      error:      (_response, _status, _xhr) => { },
      complete:   (_xhr, _status) => { }
    })
  }

  // ==== Getters

  get discountCode() {
    return this.discountCodeInputTarget.value
  }

  get selectedProductId() {
    return this.data.get('selectedProductId')
  }

  // ==== Setters

  set selectedProductId(value) {
    this.data.set('selectedProductId', value)
  }

  // ==== Private

  updateTotalHTML(total) {
    if (this.hasTotalTarget) {
      $fq(this.totalTarget).innerHTML(this.formatCurrency(total))
    }
  }

  updateTotal(id) {
    const total    = this.getInformationFromSelection(id)
    const discount = parseFloat(this.discountValueTarget.value) || 0

    this.selectedProductId = id

    this.updateTotalHTML(total - discount)
  }

  getInformationFromSelection(id) {
    const elementID = `productSelection${id}`
    const data      = document.getElementById(elementID).dataset

    return data.total
  }

  formatCurrency(value) {
    const formatter = new Intl.NumberFormat('en-NL', {
      style:    'currency',
      currency: 'EUR',
      minimumFractionDigits: 0
    })

    return formatter.format(value)
  }

  setDiscountValue(state, value) {
    if (state == 'valid') {
      $fq(this.discountValueTarget).removeClass('-clr-red')
      $fq(this.discountValueTarget).addClass('-clr-greenBright')
    } else if (state == 'invalid') {
      $fq(this.discountValueTarget).removeClass('-clr-greenBright')
      $fq(this.discountValueTarget).addClass('-clr-red')
    }

    $fq(this.discountValueTarget).innerHTML(value)
  }

  params(data) {
    return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&')
  }

  // ==== Channels

}
