import ApplicationController from 'support/application_controller'
import $fq                   from 'support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'body',
    'closeButton',
    'viewPort'
  ]

  initialize() {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey)

        if (isNotCombinedKey) {
          this.closeModal()
        }
      }
    })
  }

  connect() {}

  // ==== Controllers

  // ==== Actions

  open(_event) {
    this.openModal()
  }

  openRemote(event) {
    const [_data, _status, xhr] = event.detail
    $fq(this.bodyTarget).innerHTML(xhr.response)

    this.openModal()
  }

  close(_event) {
    this.closeModal()
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  openModal() {
    $fq(this.viewPortTarget).addClass('-open')

    if (this.hasCloseButtonTarget) {
      $fq(this.closeButtonTarget).show()
    }
  }

  closeModal() {
    $fq(this.viewPortTarget).removeClass('-open')

    if (this.hasCloseButtonTarget) {
      $fq(this.closeButtonTarget).hide()
    }
  }

  // ==== Channels

}
