import ApplicationController from 'support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'label'
  ]

  initialize() {
    this.control.dataset.label = this.label
  }

  connect() {}

  // ==== Controllers

  // ==== Actions

  setFilename(event) {
    const element  = event.currentTarget
    const fileName = element.value.split('\\').pop()

    if (fileName) {
      this.labelTarget.innerHTML = fileName
    } else {
      this.labelTarget.innerHTML = this.prompt
    }
  }

  // ==== Getters

  get control() {
    return this.element.querySelector('.c-input__control')
  }

  get prompt() {
    return this.data.get('prompt')
  }

  get label() {
    return this.data.get('label')
  }

  // ==== Setters

  // ==== Private

  // ==== Channels

}
