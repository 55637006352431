const $fq = (() => {
  'use-strict'

  class Constructor {

    constructor(selector) {
      if (!selector) return

      if (selector === 'document') {
        this.elements = [document]
      } else if (selector === 'window') {
        this.elements = [window]
      } else if (typeof selector === 'object') {
        this.elements = [selector]
      } else {
        this.elements = document.querySelectorAll(selector)
      }
    }

    // Helpers

    each(callback) {
      if (!callback || typeof callback !== 'function') return

      this.elements.forEach((el, _idx) => {
        callback(el)
      })
    }

    stringToArray(value) {
      let array

      if (Array.isArray(value)) {
        array = value
      } else {
        array = value.split(' ')
      }

      return array.filter(Boolean)
    }

    // Functions

    addClass(className) {
      this.each((item) => {
        this.stringToArray(className).forEach((name) => {
          item.classList.add(name)
        })
      })

      return this
    }

    hasClass(className) {
      if (!className) return false

      return this.elements[0].classList.contains(className)
    }

    removeClass(className) {
      this.each((item) => {
        this.stringToArray(className).forEach((name) => {
          item.classList.remove(name)
        })
      })

      return this
    }

    toggleClass(className) {
      if (!className) return this

      this.each((_item) => {
        if (this.hasClass(className)) {
          this.removeClass(className)
        } else {
          this.addClass(className)
        }
      })

      return this
    }

    innerHTML(html) {
      this.each((item) => {
        item.innerHTML = html
      })

      return this
    }

    replaceWith(html) {
      const fragment = document.createRange().createContextualFragment(html)

      this.each((item) => {
        item.replaceWith(fragment)
      })

      return this
    }

    textContent(text) {
      this.each((item) => {
        item.textContent = text
      })
    }

    changeGlyph(glyph) {
      this.each((item) => {
        const glyphSheetURL = new URL(item.querySelector('use').getAttribute('href').split('#')[0])
        glyphSheetURL.hash = glyph

        item.querySelector('use').setAttribute('href', glyphSheetURL)
      })
    }

    // Alias shorthands

    hide(className = 'u-hide') {
      this.addClass(className)

      return this
    }

    html(html) {
      this.innerHTML(html)

      return this
    }

    show(className = 'u-hide') {
      this.removeClass(className)

      return this
    }

    text(text) {
      this.textContent(text)

      return this
    }

  }

  const instantiate = selector => new Constructor(selector)

  return instantiate
})()

export default $fq
